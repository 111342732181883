// Grava pagamento
(function () {
  $(".bt-registrar-pagamento").on("click", function (e) {
    e.preventDefault();
    if ($("#form-pagamento").parsley().validate()) {
      var dados = $("#form-pagamento").serialize();
      $.ajax({
        type: "POST",
        url: url_base + "acoes/app/doacoes/grava_pagamento.php?acao=insert",
        data: dados,
        beforeSend: function () {
          showLoading();
        },
        success: function (data) {
          console.log(data);
          data = $.parseJSON(data);
          hideLoading();
          if (data.status_pagamento != 14) {
            location.href = url_base + "confirmacao/order/" + data.hash;
          } else {
            showAlert(
              "Erro",
              "Não foi possível realizar essa operação, revise os dados.",
              "error"
            );
          }
        },
        error: function (xhr, type, exception, data) {
          hideLoading();
          console.log(data);
          showAlert(
            "Erro",
            "Não foi possível realizar essa operação.",
            "error"
          );
          //console.log("ajax error response type "+type);
        },
      });
    } else {
      //$("#termos").parsley().validate();
    }
  });
})();

// Exibe/Oculta os campos da forma de pagamento escolhida
$('input[name="forma-pagamento"]').on("change", function () {
  $(".forma-pag-content").slideUp();
  $(".forma-pag-content .campo").prop("disabled", true);
  $(".forma-pag-content .campo").prop("required", false);

  $('.forma-pag-content[data-forma="' + $(this).val() + '"]').slideDown();
  $('.forma-pag-content[data-forma="' + $(this).val() + '"] .campo').prop(
    "disabled",
    false
  );
  $('.forma-pag-content[data-forma="' + $(this).val() + '"] .campo').prop(
    "required",
    true
  );
});
