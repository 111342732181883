$(function () {
  $(".vale-input").on("change", function () {
    // Vales predefinidos
    if ($(this).attr("id") != "vale-custom") {
      $("#tipo").val("pre");
      $("#descricao").val($(this).val());
      $("#valor").val($(this).data("valor"));
      $("#valor-custom").val("");
      $("#valor-custom").prop("required", false);
    }
    // Vale personalizado
    else {
      $("#tipo").val("custom");
      $("#descricao").val($(this).val());
      $("#valor").val("");
      $("#valor-custom").focus();
      $("#valor-custom").prop("required", true);
    }
  });

  // Resgata o valor personalizado
  $("#valor-custom").on("change", function () {
    if ($("#tipo").val() == "custom") {
      $("#valor").val($(this).val());
    }
  });
  $("#valor-custom").on("click", function () {
    var inputCheck = $(this)
      .parent()
      .parent()
      .parent()
      .find("input[name='doacao']");
    inputCheck.prop("checked", true);
    $("#tipo").val("custom");
    $("#descricao").val(inputCheck.val());
    $("#valor").val("");
    $("#valor-custom").focus();
    $("#valor-custom").prop("required", true);
  });

  // Botão de pagamento
  $("#form-doacao").on("submit", function () {
    if ($("#descricao").val() != "" && $("#valor").val() != "") {
      // Redireciona para a página de pagamento
      //$(this).submit();
    } else if ($("#tipo").val() == "custom" && $("#valor").val() == "") {
      showAlert("Atenção", "Informe o valor da Doação.", "warning");
      $("#valor-custom").focus();
    } else {
      showAlert("Atenção", "Escolha um Valor antes de continuar.", "warning");
    }
  });
});
