// Grava pedido
$(function () {
  function hidePagamentoLoading() {
    $(".pagamento-loading").fadeOut();
    $(".loading-hidden").each(function () {
      $(this).removeClass("loading-hidden");
    });
  }

  $("input[name='forma-pagamento']").on("click", function () {
    if ($(this).is(":checked")) {
      $("#concluir").prop("disabled", false);
    } else {
      $("#concluir").prop("disabled", true);
    }
  });

  // Somente se for a página de pagamento
  if (typeof PagSeguroDirectPayment !== "undefined") {
    // Sessão
    var dataString = {
      acao: "request_session",
    };

    var urlPagseguro = url_base + "acoes/app/doacoes/pagseguro.php";

    $.ajax({
      type: "POST",
      url: urlPagseguro,
      data: dataString,
      cache: false,
      success: function (response) {
        if (response != "	") {
          response = $.parseJSON(response);
          var id_sessao = response.id_sessao[0];
          // console.log(id_sessao);
          PagSeguroDirectPayment.setSessionId(id_sessao);

          // Meios de pagamento
          PagSeguroDirectPayment.getPaymentMethods({
            amount: $("#valorPagto").val(),
            success: function (response) {
              //console.log(response.paymentMethods.CREDIT_CARD.options);
              $("#modulo").val("Pagseguro");
              var sender_hash = PagSeguroDirectPayment.getSenderHash();
              $("#sender_hash").val(sender_hash);
              if (sender_hash != "") {
                hidePagamentoLoading();
                //$("#concluir").show();
              } else {
                hidePagamentoLoading();
                showAlert(
                  "Erro",
                  "Erro de conexão com o PagSeguro, entre em contato com a escola.",
                  "error"
                );
              }
              /*  $('.loading-formas-pag').hide();
                             $('.loading-formas-pag').html('');	 */
              var arr_meios = ["CREDIT_CARD", "BOLETO"];
              $.each(response.paymentMethods, function (index, value) {
                if (jQuery.inArray(index, arr_meios) != -1) {
                  $("#" + value.name).show();
                  $("#" + value.name).data("cod", value.code);
                  //$("#"+value.name).attr('title',value.code);
                }
                //console.log(response.paymentMethods);
              });
            },
            error: function (response) {
              // Tratamento do erro
              console.log(response.errors);
              var dataString = {
                origem: "meios_pag",
                msg: response.errors,
                acao: "log",
              };
              $.ajax({
                type: "POST",
                url: url_base + "acoes/app/pagamentos/grava_log.php",
                data: dataString,
                cache: false,
                success: function (response) {},
              });
            },
            complete: function (response) {},
          });

          // Bandeira do cartão
          $("#numero_cartao").on("change", function () {
            PagSeguroDirectPayment.getBrand({
              cardBin: $("#numero_cartao").val(),
              success: function (response) {
                // Bandeira encontrada
                var bandeira = response.brand.name;
                $("#bandeira").val(bandeira);
                // $("#cvv").attr('min','0');
                // $("#cvv").attr('max',''+response.brand.cvvSize+'');
                $("#cvv").attr("maxlength", "" + response.brand.cvvSize + "");
                $("#msg_cvv").html(
                  "<br>" +
                    response.brand.cvvSize +
                    " números no verso do cartão"
                );
                /* $("#img_bandeira").html('<img src="'+url_base+'static/img/pagamentos/brands/'+bandeira+'.png">'); */
                var sem_juros =
                  $("#max_parcelas").val() > 1 ? $("#max_parcelas").val() : "";
                if (sem_juros > 1) {
                  // Parcelamento
                  PagSeguroDirectPayment.getInstallments({
                    amount: $("#valorPagto").val(),
                    brand: bandeira,
                    maxInstallmentNoInterest: $("#max_parcelas").val(),
                    success: function (response) {
                      // Opções de parcelamento disponível
                      console.log(response.installments);
                      $("#parcelas").html("");
                      $("#parcelas").append(
                        '<option value="">Parcelas</option>'
                      );
                      $.each(response.installments, function (index, value) {
                        $.each(value, function (index, value) {
                          var juros =
                            this.interestFree == true ? "(Sem juros)" : "";
                          $("#parcelas").append(
                            '<option value="' +
                              this.quantity +
                              "x" +
                              this.installmentAmount +
                              '">' +
                              this.quantity +
                              "X de R$ " +
                              formataMoeda(this.installmentAmount) +
                              " = R$ " +
                              formataMoeda(this.totalAmount) +
                              " " +
                              juros +
                              "</option>"
                          );
                        });
                      });
                      $(".msg_invalido").hide();
                    },
                    error: function (response) {
                      // Tratamento do erro.
                      console.log(response.errors);
                      console.log(amount);
                      var dataString = {
                        origem: "parcelamento",
                        msg: response.errors,
                        acao: "log",
                      };
                      $.ajax({
                        type: "POST",
                        url: url_base + "acoes/app/pagamentos/grava_log.php",
                        data: dataString,
                        cache: false,
                        success: function (response) {},
                      });
                    },
                    complete: function (response) {},
                  });
                }
              },
              error: function (response) {
                // Tratamento do erro
                console.log(response);
                $("#bandeira").val("");
                $("#numero_cartao").val("");
                $("#cvv").val("");
                //$(".msg_invalido").show();
                //$("#msg_cvv").hide();
                //$("#img_bandeira").html('');
                var dataString = {
                  origem: "brand",
                  msg: response,
                  acao: "log",
                };
                $.ajax({
                  type: "POST",
                  url: url_base + "acoes/app/pagamentos/grava_log.php",
                  data: dataString,
                  cache: false,
                  success: function (response) {},
                });
              },
              complete: function (response) {},
            });
          });
        } else {
          hidePagamentoLoading();
          showAlert(
            "Erro",
            "Erro de conexão com o PagSeguro, entre em contato com a escola.",
            "error"
          );
          setTimeout(() => {
            window.location.href =
              url_base + pag_atual_primeira_chave + "/inicio";
          }, 3000);
        }
      },
    });

    // Tranfere parcela para o formulário
    $("#parcelas").on("change", function () {
      $("#parcelas_cartao_env").val($("#parcelas").val());
    });

    // Token cartão
    $(".campos_cartao_token").on("change", function () {
      if (
        $("#numero_cartao").val() != "" &&
        $("#cvv").val() != "" &&
        $("#validadeMes").val() != "" &&
        $("#validadeAno").val() != ""
      ) {
        var param = {
          cardNumber: $("#numero_cartao").val(),
          cvv: $("#cvv").val(),
          expirationMonth: $("#validadeMes").val(),
          expirationYear: $("#validadeAno").val(),
          success: function (response) {
            var token_cartao = response.card.token;
            $("#cartao_token").val(token_cartao);
          },
          error: function (response) {
            //console.log(response.errors);
            var dataString = {
              origem: "token_cartao",
              msg: response.errors,
              acao: "log",
            };
            $.ajax({
              type: "POST",
              url: url_base + "acoes/app/pagamentos/grava_log.php",
              data: dataString,
              cache: false,
              success: function (response) {},
            });
          },
          complete: function (response) {},
        };
        if ($("#bandeira").val() != "") {
          param.brand = $("#bandeira").val();
        }
        PagSeguroDirectPayment.createCardToken(param);
      }
    });
  }
});
